import Offcanvas from "../../../node_modules/bootstrap/js/dist/offcanvas";
import $ from "jquery";

window.LoginFormToRenderEnum = Object.freeze({
    LoginForm: 0,
    LoginBankIdForm: 1,
    CreateAccountForm: 2,
    CreateAccountBankIdForm: 3
});

$.ajaxSetup({
    xhrFields: {
        withCredentials: true
    }
});

window.OnLogin = function(result) {
    if (result.loginReturnUrl) {
        if (result.loginReturnUrl.indexOf("http") != -1) {
            window.location = result.loginReturnUrl;
        } else {
            window.location.href = result.loginReturnUrl;
        }
    }
};

window.OpenLoginCanvas = function (callback, formToRenderEnum) {
    ControlLoginElementExist(function () {
        loginCanvas.show();
        if ($.isFunction(callback)) {
            callback();
        }
    }, formToRenderEnum);
};

window.HideLoginCanvas = function () {
    if (!$("#loginCanvas").length) {
        return;
    }

    loginCanvas.hide();
};

window.InitLoginPartialEvents = function () {
    $(document).on("click",
        "#loginFormToggleButton",
        function () {
            $("#loginFormToggleButton").addClass("active");
            $("#createAccountFormToggleButton").removeClass("active");
            $("#loginCanvas").addClass("background-primary");
            $("#loginCanvas").removeClass("background-secondary");
        });

    $(document).on("click",
        "#createAccountFormToggleButton",
        function () {
            $("#createAccountFormToggleButton").addClass("active");
            $("#loginFormToggleButton").removeClass("active");
            $("#loginCanvas").removeClass("background-primary");
            $("#loginCanvas").addClass("background-secondary");
        });

    var loginCanvas = document.getElementById("loginCanvas");
    loginCanvas.addEventListener("shown.bs.offcanvas",
        function () {
            if ($(".offcanvas-backdrop").length > 1) {
                $(".offcanvas-backdrop").not(":first").remove();
            }
        });
        
}

window.SetFormForLoginCanvas = function(formToRenderEnum) {
    $.ajax({
        type: 'GET',
        url: '/Login/LoginPartialDefaultContent',
        xhrFields: {
            withCredentials: true
        },
        data: {
            formToRender: formToRenderEnum 
        },
        success: function (loginCanvasHtml) {
            $('#loginCanvasContentWrapper').html(loginCanvasHtml);
        }
    });

}

window.ControlLoginElementExist = function (callback, formToRenderEnum = LoginFormToRenderEnum.LoginForm) {
    let loginCanvasElement = document.getElementById("loginCanvas");
    if (loginCanvasElement) {
        SetFormForLoginCanvas(formToRenderEnum);
 
        callback();
        return;
    }

    let formToRender = formToRenderEnum;

    $.ajax({
        type: 'GET',
        url: '/Login/LoginPartial',
        xhrFields: {
            withCredentials: true
        },
        data: {
            formToRender: formToRender 
        },
        success: function (loginCanvasHtml) {
            $('body').append(loginCanvasHtml);
            var loginCanvasElement = document.getElementById("loginCanvas");
            window.loginCanvas = new Offcanvas(loginCanvasElement,
                {
                    focus: true,
                    keyboard: true,
                    backdrop: true
                });

            InitLoginPartialEvents();

            callback();
        }
    });
};


(function () {
    $(document).on("click",
        "#createAccountSectionButton",
        function () {
            OpenLoginCanvas(function () {
                $("#createAccountFormToggleButton").click();
            }, 
            LoginFormToRenderEnum.CreateAccountForm);
       });

    $(document).on("click",
        ".openLoginCanvas",
        function () {
            OpenLoginCanvas(function () {
                $("#loginFormToggleButton").click();
            }, 
            LoginFormToRenderEnum.LoginForm);
        });

    $(document).on("click",
        ".hide-login-canvas",
        function () {
            HideLoginCanvas();
        });

    var hash = window.location.hash.substr(1);

    if (hash.toLowerCase() === "loggain") {
        OpenLoginCanvas();
    } else if (hash.toLowerCase() === "skapakonto") {
        OpenLoginCanvas(function () {
            document.getElementById("createAccountFormToggleButton").click();
        }, 
        LoginFormToRenderEnum.CreateAccountForm);
    } else if (hash.toLowerCase() === "glomtlosenord") {
        OpenLoginCanvas(function () {
            document.getElementById("forgotPasswordLink").click();
        });
    }
    $(document).on("click",
        ".open-create-account-bankid",
        function () {
            OpenLoginCanvas(function () {
                $("#createAccountFormToggleButton").click();
            }, 
            LoginFormToRenderEnum.CreateAccountBankIdForm)
        });
        
    $(document).on("click",
        ".open-sing-in-bankid",
        function () {
            OpenLoginCanvas(function () {
                $("#loginFormToggleButton").click();
            }, 
            LoginFormToRenderEnum.LoginBankIdForm)
        });
})();
